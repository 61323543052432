<template>
  <v-row>
    <v-col cols="12">
      <base-material-card
        :color="contractType === 1 ? 'success' : 'primary'"
        :title="$t('invoices.Monthly_invoices')"
        icon="mdi-currency-usd"
        inline
        class="px-5 py-3"
      >
        <!--  Table pour les Factures Mesuelles (categorie 1)-->
        <v-data-table
          :headers="tableHeaders"
          :items="factureMensuelle"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :expanded.sync="expanded"
          item-key="id"
          @page-count="pageCount = $event"
        >
          <!--  ACTIONS-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.edit="{ item }">
            <!--  Edit-->
            <!--  Pour le opération-->
            <v-btn
              v-if="$route.name !== 'Dashboard'"
              fab
              dark
              x-small
              color="success"
              :title="$t('Edit')"
              @click="editItem(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <!--  View-->
            <v-btn
              class="ml-2"
              fab
              dark
              x-small
              color="white"
              :title="$t('View')"
              @click="viewItem(item)"
            >
              <v-icon>{{ getButtonIcon(item.id) }}</v-icon>
            </v-btn>
          </template>
          <!--  DETAIL-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.detail="{ item }">
            <span class="primary--text">{{ `[ ${item.periode} ${item.fournisseur.toUpperCase()} ]` }}</span>
            <!--  Id de la facture-->
            <span> {{ `id: ${item.id}` }}</span>
            <!--  Pour corporatif 2 seulement et autre-->
            <template v-if="item.type === 2">
              <!--  Si Paiement est null, il doit payer la facture-->
              <v-btn
                v-if="checkPaiment(item)"
                color="pink accent-4"
                x-small
                class="ma-2"
                @click="payerFacture(item)"
              >
                <v-icon
                  left
                  dark
                  color="yellow accent-3"
                >
                  mdi-alert
                </v-icon>
                {{ $t('invoices.To_pay') }}
              </v-btn>

              <!--  Si facture est payé-->
              <v-btn
                v-if="item.paiementId"
                color="white"
                x-small
                class="ma-2"
                @click="openDialogPaiementFacture(item.id)"
              >
                {{ $t('contract.Bill') }}
                <v-icon
                  right
                  dark
                >
                  mdi-receipt
                </v-icon>
              </v-btn>
            </template>

            <!--  Lot-->
            <!--  Pour corporatif 1 seulement-->
            <template v-if="item.type === 1 && item.lot">
              [ Lot: {{ item.lot }} ]
            </template>
          </template>
          <!--  Total-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.total="{ item }">
            {{ item.montant_total.toFixed(2) }}$
          </template>
          <!--  OPTIONS-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.options="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <!--  Détail de la facture-->
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row v-if="item.detail_frais">
                <!--  Détail des frais -->
                <v-col
                  v-if="item.detail_frais.frais.total"
                  cols="12"
                  :lg="getFactureColSize(item.detail_frais)"
                >
                  <v-card
                    tile
                  >
                    <v-toolbar
                      color="primary"
                      dark
                    >
                      <v-icon
                        class="mr-2"
                        x-large
                      >
                        mdi-currency-usd
                      </v-icon>

                      <v-toolbar-title>{{ $t('contract.bill.Details_costs').toUpperCase() }}</v-toolbar-title>
                    </v-toolbar>

                    <v-list>
                      <!--  FRAIS VOIX [DE BASE]-->
                      <template v-if="item.detail_frais.frais.frais_voix_de_base">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ $t('contract.bill.Voice_charges_basic').toUpperCase() }} :</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.detail_frais.frais.frais_voix_de_base }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  FRAIS VOIX [UTILISATION]-->
                      <template v-if="item.detail_frais.frais.frais_voix_utilisation">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ $t('contract.bill.Voice_usage_costs').toUpperCase() }} :</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.detail_frais.frais.frais_voix_utilisation }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  FRAIS DONNÉES ET OPTIONS [ DE BASE ]-->
                      <template v-if="item.detail_frais.frais.frais_donnees_et_options_de_base">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ $t('contract.bill.Data_charges_and_options_basic').toUpperCase() }} :</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.detail_frais.frais.frais_donnees_et_options_de_base }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  FRAIS DONNÉES ET OPTIONS [UTILISATION]-->
                      <template v-if="item.detail_frais.frais.frais_donnees_et_options_utilisation">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ $t('contract.bill.Data_charges_and_options_use').toUpperCase() }} :</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.detail_frais.frais.frais_donnees_et_options_utilisation }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  FRAIS INTERURBAIN-->
                      <template v-if="item.detail_frais.frais.frais_interurbain">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              {{ $t('contract.bill.Long_distance_charges').toUpperCase() }} :
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.detail_frais.frais.frais_interurbain }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  FRAIS INTERURBAIN-->
                      <template v-if="item.detail_frais.frais.frais_itinerance">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              {{ $t('contract.bill.Roaming_charges').toUpperCase() }} :
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.detail_frais.frais.frais_itinerance }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  AUTRE FRAIS-->
                      <template v-if="item.frais_autre">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ $t('contract.bill.Other_charge').toUpperCase() }} :</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.frais_autre }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  ADMIN-->
                      <template v-if="item.frais_administration">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ $t('contract.bill.Admin').toUpperCase() }} :</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ item.frais_administration }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  SOUS TOTAL-->
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-bold">
                            {{ $t('contract.bill.Total_before_tax').toUpperCase() }} :
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <span class="text-bold">
                            {{ item.montant_at }}
                          </span>
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider />

                      <!--  FRAIS AJUSTEMENT-->
                      <template v-if="item.frais_ajustement">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle class="red--text text-bold">
                              {{ $t('Adjustment_fee').toUpperCase() }} :
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="red--text text-bold">
                            {{ item.frais_ajustement }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle class="red--text text-bold">
                              {{ $t('Adjusted_total').toUpperCase() }} :
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="red--text text-bold">
                            {{ calculTotalAjuste(item.frais_ajustement, item.montant_at) }}
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider />
                      </template>

                      <!--  TPS-->
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            TPS :
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{ item.tps }}
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider />

                      <!--  TVQ-->
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            TVQ :
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{ item.tvq }}
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider />

                      <!--  TOTAL-->
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-bold">
                            TOTAL :
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <span class="text-bold">
                            {{ item.montant_total }}
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <!--  Détail des appels -->
                <v-col
                  v-if="item.detail_frais.appels.minutes_jour"
                  cols="12"
                  :lg="getFactureColSize(item.detail_frais)"
                >
                  <v-card
                    tile
                  >
                    <v-toolbar
                      color="primary"
                      dark
                    >
                      <v-icon
                        class="mr-2"
                        x-large
                      >
                        mdi-phone
                      </v-icon>

                      <v-toolbar-title>{{ $t('contract.bill.Call_details').toUpperCase() }}</v-toolbar-title>

                      <v-spacer />

                      <v-tooltip
                        top
                      >
                        <template #activator="{ on, attrs }">
                          <span
                            class="pointer"
                            @click="openDialogdialogCallDetails(item.id)"
                          >{{ $t('contract.bill.Display_details') }}</span>
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openDialogdialogCallDetails(item.id)"
                          >
                            <v-icon>mdi-file-table</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('contract.calls.Call_list') }}</span>
                      </v-tooltip>
                    </v-toolbar>

                    <v-list>
                      <!--  MINUTES [ JOURS ]-->
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('contract.calls.Days').toUpperCase() }} :</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{ item.detail_frais.appels.minutes_jour }}
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider />

                      <!--  MINUTES [ SOIRÉE ]-->
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('contract.calls.Evening').toUpperCase() }} :</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{ item.detail_frais.appels.minutes_soir }}
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider />

                      <!--  MINUTES [ FIN DE SEMAINE ]-->
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('contract.calls.Weekend').toUpperCase() }} :</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{ item.detail_frais.appels.minutes_fin_de_semaine }}
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <!--  Détail du data -->
                <v-col
                  v-if="item.detail_frais.data.length"
                  cols="12"
                  :lg="getFactureColSize(item.detail_frais)"
                >
                  <v-card
                    tile
                  >
                    <v-toolbar
                      color="primary"
                      dark
                    >
                      <v-icon
                        class="mr-2"
                        x-large
                      >
                        mdi-wifi
                      </v-icon>

                      <v-icon
                        class="mr-2"
                        x-large
                      >
                        mdi-chat-outline
                      </v-icon>

                      <v-toolbar-title>DATA</v-toolbar-title>
                    </v-toolbar>

                    <v-list>
                      <div
                        v-for="(dataItem, index) in item.detail_frais.data"
                        :key="index"
                      >
                        <v-list-item v-if="dataItem.key">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t(`contract.data.${dataItem.key}`).toUpperCase() }} :</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ dataItem.value }}
                          </v-list-item-action>
                        </v-list-item>

                        <!--  On ne fait pas apparaître le dernier divider-->
                        <v-divider v-if="(index + 1) !== item.detail_frais.data.length" />
                      </div>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          />
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <!-- <v-text-field
                  :value="itemsPerPage"
                  :label="$t('table.Items_per_page')"
                  type="number"
                  min="-1"
                  max="15"
                  @input="itemsPerPage = parseInt($event, 10)"
                /> -->
              <v-select
                v-model="itemsPerPage"
                :items="itemsPage"
                :label="$t('table.Bills_per_page')"
              />
            </v-col>
          </v-row>
        </div>
      </base-material-card>

      <!--  Dialog reçu-->
      <v-dialog
        v-model="dialogPaiement"
        max-width="1000"
      >
        <v-card>
          <!--  Print-->
          <v-icon
            class="mt-5"
            right
            dark
            large
            color="primary"
            @click="print"
          >
            mdi-printer
          </v-icon>
          <v-card-title>
            <v-icon
              aria-label="Close"
              @click="dialogPaiement = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="body-1 text-center">
            <v-row>
              <v-col
                cols="12"
              >
                <div id="printMe">
                  <div v-html="recuFormat" />
                </div>
              </v-col>
            </v-row>

            <v-btn
              class="mt-2 ml-4"
              color="info"
              depressed
              default
              rounded
              @click="dialogPaiement = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--  Dialog détails des appels-->
      <v-dialog
        v-model="dialogCallDetails"
        max-width="1000"
      >
        <v-card>
          <v-card-title>
            {{ $t('contract.calls.Call_list') }}

            <v-spacer />

            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('contract.calls_detail.Date_hour').toUpperCase() }}
                    </th>
                    <th class="text-left">
                      {{ $t('contract.calls_detail.Origin').toUpperCase() }}
                    </th>
                    <th class="text-left">
                      {{ $t('contract.calls_detail.Destination_no').toUpperCase() }}
                    </th>
                    <th class="text-left">
                      {{ $t('contract.calls_detail.Location').toUpperCase() }}
                    </th>
                    <th class="text-left">
                      {{ $t('contract.calls_detail.Duration').toUpperCase() }}
                    </th>
                    <th class="text-left">
                      {{ $t('contract.calls_detail.Cost').toUpperCase() }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="itemCall in callsDetail"
                    :key="itemCall.name"
                  >
                    <td class="text-left">
                      {{ itemCall.date }} {{ itemCall.heure }}
                    </td>
                    <td class="text-left">
                      {{ itemCall.de }}
                    </td>
                    <td class="text-left">
                      {{ itemCall.numero_appele }}
                    </td>
                    <td class="text-left">
                      {{ itemCall.a }}
                    </td>
                    <td class="text-left">
                      {{ itemCall.duree }}
                    </td>
                    <td class="text-left">
                      {{ itemCall.total }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-icon
              aria-label="Close"
              @click="dialogCallDetails = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="body-1 text-center">
            <v-row>
              <v-col
                cols="12"
              >
                <div id="printMe">
                  <div v-html="recuFormat" />
                </div>
              </v-col>
            </v-row>

            <v-btn
              class="mt-2 ml-4"
              color="info"
              depressed
              default
              rounded
              @click="dialogCallDetails = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
  // Service
  import FacturesService from '@/services/01Cell/FacturesService'
  // Mixins
  import { Payment } from '@/mixins/Payment/Payment'
  import { FacturePaiement } from '@/mixins/Facture/FacturePaiement.js'

  export default {
    name: 'FactureComponent',
    mixins: [Payment, FacturePaiement],
    props: {
      factures: Array,
      parameterFournisseurPaiement: String,
      parameterModePaiement: String,
      contractType: Number,
    },
    data () {
      return {
        paimentUni: process.env.VUE_APP_PAIEMENT_UNI,
        dialogCallDetails: false,
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        itemsPage: [5, 10, 25, 50, 100],
        expanded: [],
        singleExpand: false,
        tableHeaders: [],
        factureHeaders: [
          { text: '', value: 'edit', sortable: false, class: 'text-h4 primary--text', width: '40px' },
          {
            text: this.$i18n.t('Detail'),
            align: 'start',
            sortable: false,
            value: 'detail',
            class: 'text-h4 primary--text',
          },
          { text: '$ Total', align: 'end', value: 'total', sortable: false, class: 'text-h4 primary--text' },
        ],
        factureHeadersOperation: [
          { text: '', value: 'edit', sortable: false, class: 'text-h4 primary--text', width: '120px' },
          {
            text: this.$i18n.t('Detail'),
            align: 'start',
            sortable: false,
            value: 'detail',
            class: 'text-h4 primary--text',
          },
          { text: '$ Total', align: 'end', value: 'total', sortable: false, class: 'text-h4 primary--text' },
          { text: 'Options', align: 'end', value: 'options', sortable: false, class: 'text-h4 primary--text' },
        ],
        callsDetail: [],
      }
    },

    computed: {
      factureMensuelle () {
        if (this.$route.name === 'Dashboard') {
          // Factures Mensuelles est la catégorie 1 (mensuelle) et le statut est 1 (Facture émise) statut est 2 (En erreur par token (automatique))
          return this.factures.filter(item => item.categorie === 1 && item.statut === 1).sort((a, b) => (a.periode < b.periode) ? 1 : -1)
        }

        // Admin
        // Factures Mensuelles est la catégorie 1 (mensuelle)
        return this.factures.filter(item => item.categorie === 1).sort((a, b) => (a.periode < b.periode) ? 1 : -1)
      },
    },

    created () {
      // Pas les opérations
      if (this.$route.name === 'Dashboard') {
        this.tableHeaders = this.factureHeaders
      } else {
        // Pour les opérations
        this.tableHeaders = this.factureHeadersOperation
      }
    },

    // async mounted () {
    //   try {
    //     this.factures = this.contratId
    //     // this.factures = contrat.Factures
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    methods: {
      calculTotalAjuste (ajustement, sousTotal) {
        return (parseFloat(ajustement, 10) + parseFloat(sousTotal, 10)).toFixed(2)
      },
      checkPaiment (facture) {
        // Si le paiement automatique seulement, le bouton n'apparaît
        if (this.parameterModePaiement === '2') return false

        // Si il y a un un InscriptionPaiementId (Carte de crédit associé) c'est un paiement automatique. On affiche pas le bouton
        if (facture.InscriptionPaiementId) return false

        // On affiche le bouton si le if est vrai
        if (facture.categorie === 1 && facture.montant_total > 0 && facture.statut !== -1 && !facture.paiementId) return true
        return false
      },
      getButtonIcon (id) {
        if (this.expanded.some(item => item.id === id)) {
          return 'mdi-eye-off-outline'
        }
        return 'mdi-eye-outline'
      },
      getFactureColSize (item) {
        // 4 6 12
        let col = 0
        if (item.frais.total) col++
        if (item.appels.minutes_jour) col++
        if (item.data.length) col++
        return 12 / col
      },
      editItem (value) {
        const index = this.expanded.indexOf(value)
        if (index === -1) {
          this.expanded.push(value)
        } else {
          this.expanded.splice(index, 1)
        }
      },
      viewItem (value) {
        const index = this.expanded.indexOf(value)
        if (index === -1) {
          this.expanded.push(value)
        } else {
          this.expanded.splice(index, 1)
        }
      },
      async openDialogdialogCallDetails (factureId) {
        // Start Spinner
        this.$loading.show()
        try {
          this.callsDetail = await FacturesService.callsDetail(factureId)
          // Stop Spinner
          this.$loading.hide()
          // Show dialog
          this.dialogCallDetails = true
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>

<style scoped>
  .text-bold {
    font-weight: bold;
  }

  .pointer {
    cursor: pointer;
  }
</style>
