  // Service
  import FacturationsService from '@/services/01Cell/FacturationsService'
  // Other
  import Vue from 'vue'
  import VueHtmlToPaper from 'vue-html-to-paper'
  const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes',
    ],
    styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css',
    ],
  }

  Vue.use(VueHtmlToPaper, options)

export const FacturePaiement = {
    data () {
      return {
        paimentUni: process.env.VUE_APP_PAIEMENT_UNI,
        dialogPaiement: false,
        dialogInvoiceUnPaid: false,
        recuFormat: '',
        dialogInvoiceUnPaidMessage: '',
      }
    },

    computed: {
    },

    methods: {
      openDialogPaiement (recu) {
        console.log(recu)
        // // Traduction du paiement
        // this.recuFormat = this.translatePayment(recu)
        // this.dialogPaiement = true
      },
      async openDialogPaiementFacture (factureId) {
        // Start Spinner
        this.$loading.show()
        try {
          const html = await FacturationsService.getFactureHtml(factureId)
          // // Traduction du paiement
          this.recuFormat = html
          this.dialogPaiement = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
      print () {
        // Pass the element id here
        this.$htmlToPaper('printMe')
      },
      payerFacture (facture) {
        // Si c'est une facture de catégorie 2 (frais de retard), on s'assure que toutes les factures de catégorie 1 soit fini de payer pour ce contrat
        if (facture.categorie === 2) {
            // On trouve le contrat de la facture
            const findContrat = this.client.Contrats.find(item => item.id === facture.ContratId)
            // Dans les factures mensuelles, on s'assure que le client est payé toutes ses factures mensuelle
            const findInvoiceUnPaid = findContrat.Factures.filter(item => item.categorie === 1 && !item.Paiement)
            if (findInvoiceUnPaid.length) {
                this.dialogInvoiceUnPaid = true
                this.dialogInvoiceUnPaidMessage = `${this.$i18n.t('contract.bill.warning.You_must_first_pay_all_your_invoices_for_the_contract')} <b>${`(${findContrat.no.slice(0, 3)}) ${findContrat.no.slice(3, 6)}-${findContrat.no.slice(6)}`}</b> ${this.$i18n.t('contract.bill.warning.before_you_can_pay_this_invoice')}.`
                return
            }
        }

        if (this.parameterFournisseurPaiement === 'paiementuni') {
          // Pour anglais, il faut mettre 'an'
          const language = this.$i18n.t('Language') === 'fr' ? 'fr' : 'an'
          const http = `https://www.paiementuni.com/uqam/cc/cc-form.cfm?tps=${facture.tps}&tvp=${facture.tvq}&tvh=0.00&MontantSansTaxe=${facture.montant_at}&Montant=${facture.montant_total}&SiteID=${this.paimentUni}&NoTransaction=${facture.id}&MarchandID=30&Langue=_${language}`
          // Ex: https://www.paiementuni.com/uqam/cc/cc-form.cfm?tps=1&tvp=1&tvh=0.00&MontantSansTaxe=5&Montant=7&SiteID=667&NoTransaction=122222&MarchandID=30&Langue=_fr
          window.location.href = http
        }
        if (this.parameterFournisseurPaiement === 'paysafe') {
          console.log('A faire')
        }
      },
      returnToContract () {
        this.dialogInvoiceUnPaid = false
        this.$emit('return-to-contract')
      },
    },
}
